import { gsap } from "gsap";
import LocomotiveScroll from 'locomotive-scroll';
import Lenis from '@studio-freight/lenis'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { EaselPlugin } from "gsap/EaselPlugin";
// Import Highway
import Highway from '@dogstudio/highway';


gsap.registerPlugin(ScrollTrigger,ScrollToPlugin,EaselPlugin);

// For Live Projects
window.addEventListener('load',function(){
document.querySelector('body').classList.add("loaded")  
});


const lenis = new Lenis({
	lerp:0.07,
	duration:1.2,

});

lenis.on('scroll', (e) => {
  console.log(e)
})

lenis.on('scroll', ScrollTrigger.update)

gsap.ticker.add((time)=>{
  lenis.raf(time * 1000)
})

gsap.ticker.lagSmoothing(0)

document.addEventListener('DOMContentLoaded', function () {
            const toggleButton = document.querySelector('.toggle button');
            const container = document.querySelector('.container');
            const links = document.querySelectorAll('.link');
            let isOpen = false;

            const timeline = gsap.timeline({ paused: true });

            timeline.to(container, {
                duration: 1,
                ease: "power3.inOut",
                clipPath: 'polygon(0 0, 0 100%, 100% 100%, 100% 0)',
            });

            timeline.to(links, {
                duration: 1,
                top: 0,
                opacity: 1,
                stagger: 0.05,
                ease: "power3.inOut",
            }, "-=1"); 

            toggleButton.addEventListener('click', function() {
                if (isOpen) {
                    timeline.reverse();
                } else {
                    timeline.play();
                }
            isOpen = !isOpen;
            });
        });

const tl1 = gsap.timeline({
  scrollTrigger:{
    scrub:1.6,
    trigger:"#micro-welcome",
    start:"top top",
    end:"bottom 100%",
  },
});

tl1.from(".intro", {scale:1.5, y:100, opacity:0,stagger:.2, duration:1}, 0);
tl1.from(".flavors-01", {scale:1.4, yPercent:50,opacity:0, duration:2}, 0);
tl1.from(".micro", {scale:1.4, yPercent:50,opacity:0, stagger:.2, duration:1, ease: "power1.in"});
tl1.to(".pl-2", {yPercent:-15,}, 0);

gsap.to(".main-01", {
  scale:1.5,
  ease: "none",
  scrollTrigger: {
    trigger: ".micro-01",
    // start: "top bottom", // the default values
    // end: "bottom top",
    scrub: 2,
  }, 
});

gsap.from(".wrapper", { opacity: 0, y: 100, duration: 1, delay:2 });

const tl2 = gsap.timeline({
  scrollTrigger:{
    scrub:2,
    trigger:"#micro-reel",
    start:"top center",
    end:"bottom bottom",
  },
});

tl2.from(".reel", {scale:1.5, y:100, opacity:0,stagger:.2, duration:1});

const scene1 = gsap.timeline({
  scrollTrigger:{
    scrub:1.6,
    trigger:"#live-resin",
    start:"top center",
    end:"bottom +=200",
  },
});
	scene1.from(".wrapper5", { opacity:0.5,yPercent: 10.6 });
	scene1.from(".flex-ctn", { opacity:0.5,yPercent: 100.6 }, 0);
	scene1.to(".gold-01", { yPercent: 70.6, x:-10, rotate:30, ease: "power1.in" }, 0.01);
	scene1.to(".chrome-01", { yPercent: 90.6, rotate:-15, ease: "power1.in" }, 0);
	scene1.to(".rose-01", { yPercent: 100.8, rotate:30, ease: "power1.in" }, 0);
  scene1.to(".lr-01", { scale:1.5, ease: "power1.in" }, 0);
  scene1.to(".lr-02", { yPercent: 10.6  }, 0);
  scene1.to(".lr-03", { y: -89.7}, 0);


const scene2 = gsap.timeline({
  scrollTrigger:{
    scrub:3,
    trigger:"#quick-strike",
    start:"top center",
    end:"bottom 100%",
  },
});

scene2.to(".qs-02", { yPercent: 185, rotate:-45, ease: "power1.in" }, 0);
scene2.to(".qs-01", { y: -200, scale:1, ease: "power1.in" }, 0);
scene2.to(".qs-03", { yPercent: -30, ease: "power1.in" }, 0);


const scene4 = gsap.timeline({
  scrollTrigger:{
    scrub:2,
    trigger:"#mainline",
    start:"top top",
    end:"bottom top",
  },
});

scene4.to(".hero-title",{scale:3.5, y:-200, opacity:0, ease: "power1.in" }, 0);


const scene5 = gsap.timeline({
  scrollTrigger:{
    scrub:2,
    trigger:"#mainline-block",
    start:"top center",
    end:"bottom 100%",
  },
});

scene5.from("#mainline-block",{opacity:0,}, 0);
scene5.from(".block",{opacity:0, y:100, stagger:0.2}, 0);

const scene6 = gsap.timeline({
  scrollTrigger:{
    scrub:2,
    trigger:"#mainline-spec",
    start:"top bottom",
    end:"35% top",
  },
});

scene6.to(".ml-03",{yPercent:-28, rotate:10, scale:0.9}, 0)
scene6.from("#mainline-spec",{opacity:0,}, 0);
scene6.to(".ml-02",{yPercent:-20}, 0);


const details = gsap.timeline({
  scrollTrigger:{
    scrub:2,
    trigger:"#mainline-airflow",
    start:"top top",
    end:"+=500",
  },
});

details.from(".row",{opacity:0, y:200,stagger:0.5,duration:2}); 

// gsap.to(".ml-04", {
//     duration: 1.5,
//     y: -20,
//     repeat: -1,
//     yoyo: true,
//     ease: "sine.inOut"
// });

// gsap.to(".ml-03", {
//     duration: 1.5,
//     y: -20,
//     repeat: -1,
//     yoyo: true,
//     ease: "sine.inOut"
// });

// gsap.to(".ml-bar", {
//     duration: 1.5,
//     y: -10,
//     repeat: -1,
//     yoyo: true,
//     ease: "sine.inOut"
// });


